import { Grid, Typography, useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { purples } from "../theme";

const TopBanner = ({ heading, texts }) => {
  const smallWidth = useMediaQuery("(max-device-width: 400px)");

  return (
    <Grid
      sx={{
        height: "100%",
      }}
      direction="column"
      alignContent="center"
      justifyContent="center"
      container
    >
      <Grid item>
        <Typography
          sx={{
            color: "white",
            textShadow: `0 0 3px ${purples[3]}`,
          }}
          variant={smallWidth ? "h3" : "h2"}
          display="block"
          align="center"
          gutterBottom
        >
          {heading}
        </Typography>
      </Grid>
      {texts && (
        <Grid item>
          <Typography
            sx={{
              color: "white",
              textShadow: `0 0 3px ${purples[3]}`,
            }}
            variant="h4"
            align="center"
          >
            {texts.map((text) => (
              <Fragment key={text}>
                <span
                  style={{
                    whiteSpace: "noWrap",
                  }}
                >
                  {text}
                </span>{" "}
              </Fragment>
            ))}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

TopBanner.propTypes = {
  heading: PropTypes.string.isRequired,
  texts: PropTypes.array,
};

TopBanner.defaultProps = {
  texts: null,
};

export default TopBanner;
