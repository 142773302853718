import { Grid } from "@mui/material";
import BackgroundImage from "gatsby-background-image";
import { convertToBgImage } from "gbimage-bridge";
import PropTypes from "prop-types";
import React from "react";

const BackgroundSection = ({
  backgroundPosition,
  backgroundImage,
  children,
}) => {
  const bgImage = convertToBgImage(backgroundImage);

  return (
    <BackgroundImage Tag="section" backgroundColor={`#040e18`} {...bgImage}>
      <Grid
        sx={{
          paddingTop: (theme) => theme.spacing(10),
          minHeight: "100vh",
          minWidth: "100%",
          backgroundPosition,
        }}
        container
      >
        <div
          style={{
            minHeight: "100%",
            minWidth: "100%",
          }}
        >
          {children}
        </div>
      </Grid>
    </BackgroundImage>
  );
};

BackgroundSection.propTypes = {
  backgroundPosition: PropTypes.string.isRequired,
  backgroundImage: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default BackgroundSection;
